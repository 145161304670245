import { Error } from './Error'
import React from 'react'
import Select from 'react-select'

export const SalesTaxField = ({ testId, input, meta, className, name, classNamePrefix, labelText, placeholderText }) => {
    const selectStyles = {
        control: styles => ({
            ...styles,
            border: 'none',
            borderBottom: '1px solid #bce6ff',
            borderRadius: 0,
            transform: 'translateY(-5px)',
            '&:hover': { borderBottom: '1px solid #a8a8a8' }
        }),
        placeholder: styles => ({
            ...styles,
            color: '#1175a9',
            fontWeight: 'bold',
            transform: 'translateY(-7px)'
        })
    }

    const options = [
        { value: 'loan', label: 'Include in the loan'},
        { value: 'dmv',  label: 'Pay directly to your DMV'}
    ]

    return <div data-testid={testId} className={className}>
        {!meta.pristine && <div className="label fade-in"><label>{labelText}</label></div>}
        <Select
            name={name}
            styles={selectStyles}
            classNamePrefix={classNamePrefix}
            value={input.value}
            placeholder={placeholderText}
            onChange={input.onChange}
            options={options}
            />
        <Error {...meta} />
    </div>
}