import 'react-datepicker/dist/react-datepicker.css'

import { CheckboxOption, RadioOptionGroup } from '@Components/shared/RadioComponents'
import { Col, Row } from 'react-bootstrap'
import { DatePickerField, InputField, InputFieldMask, SelectField, SalesTaxField } from './FieldBody'
import { Field, Form } from 'react-final-form'
import React, { useContext, useState } from 'react'
import { handleUserDeauth, logError } from '@Services'

import { AuthContext } from '@Contexts/Auth'
import { ReCAPTCHAContainer } from '@Components/shared/ReCAPTCHA'
import Terms from '@Components/shared/Legal/Terms'
import createDecorator from 'final-form-focus'
import { httpPost } from '@Services/API'
import { isValidEmail } from '@Helpers'
import { navigate } from 'gatsby'
import { updateTimestamp } from '@Services/Credentials'

const focusOnError = createDecorator()
let ReservationForm = ({ location, setApiError }) => {
  const [reCaptchaVerified, setReCaptchaVerified] = useState(false)
  const [reCaptchaError, setReCaptchaError] = useState(false)
  const { state, dispatch } = useContext(AuthContext)

  let onSubmit = (values) => {
    if (!reCaptchaVerified) {
      setReCaptchaError(true)
      return
    }
    const isFinancing = values.financing.toLowerCase() === 'yes';
    let formInfo = {
      stockid: location.state.stockid,
      buyer: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address_line1: values.address,
        address_line2: values.addressTwo || '',
        city: values.city,
        state: values.state.value,
        zip: values.zip,
        driver_license_number: values.licenseNumber,
        driver_license_state: values.licenseState.value,
        dob: values.dob,
      },
      is_co_buyer: values.coBuyer,
      co_buyer: {
        cobuyer_name: values.coBuyerName || '',
        driver_license_number: values.coBuyerLicenseNumber || '',
        driver_license_state: values.coBuyerLicenseState?.value || '',
        dob: values.coBuyerDob || '',
      },
      is_financing: values.financing,
      financing: {
        bank_name: isFinancing? (values.bankName || '') : '',
        loan_officer_name: isFinancing? (values.loanOfficerName || '') : '',
        bank_phone: isFinancing? (values.bankPhone || '') : '',
        sales_tax: isFinancing? (values.salesTax?.value || '') : '',
      },
      order_inspection: values.inspection,
      warranty_quote: values.protection,
    }
    httpPost('/reserve', formInfo, state.token)
      .then((response) => {
        updateTimestamp()
        navigate('/Confirmation/', { state: response })
      }).catch(error => {
        setApiError(error.request.status)
        if (handleUserDeauth(error, dispatch)) return
        logError(error)
      })
  }

  const required = value => value ? undefined : 'Required'
  const validEmail = value => isValidEmail(value) ? undefined : 'Invalid Email'

  const Condition = ({ when, is, children }) =>
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>

  return <Col className='reservation-form'>
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row className='one-line'>
            <Field name='name' validate={required}>
              {props => <InputField {...props} labelText='Buyer Name*' />}
            </Field>
          </Row>
          <div data-testid='two-line-group'>
            <Row className='two-line'>
              <Field name='email' validate={(value) => (required(value) || validEmail(value))}>
                {props => <InputField {...props} labelText='Email*' />}
              </Field>
              <Field name='phone' validate={required} type='number'>
                {props => <InputFieldMask testId='phone' {...props} maskPlaceholder='Phone' mask={'999-999-9999'} />}
              </Field>
            </Row>
          </div>
          <Row className='two-line'>
            <Field name='address' validate={required}>
              {props => <InputField {...props} labelText='Address*' />}
            </Field>
            <Field name='addressTwo'>
              {props => <InputField {...props} labelText='Address 2' />}
            </Field>
          </Row>
          <Row className='three-line'>
            <Field name='city' validate={required}>
              {props => <InputField {...props} labelText='City*' />}
            </Field>
            <Field name='state' validate={required}>
              {props => <SelectField
                testId='state'
                {...props}
                className='reservation-form-input-field half'
                name='state'
                labelText='State*'
                placeholderText='State*'
              />}
            </Field>
            <Field name='zip' validate={required}>
              {props => <InputField {...props} labelText='Zip*' />}
            </Field>
          </Row>
          <Row className='three-line'>
            <Field name='licenseNumber' validate={required}>
              {props => <InputField {...props} labelText="Driver's License Number*" />}
            </Field>
            <Field name='licenseState' validate={required}>
              {props => <SelectField
                testId='licenseState'
                {...props}
                className='reservation-form-input-field'
                name='licenseState'
                labelText='State*'
                placeholderText='License State*'
              />}
            </Field>
            <Field name='dob' validate={required}>
              {props => <DatePickerField
                testId='dob'
                {...props}
                className='reservation-form-input-field'
                labelText='Date of Birth*'
                placeholderText='Date of Birth (mm/dd/yyyy)*'
              />}
            </Field>
          </Row>
          <div data-testid='co-buyer-group' className='radio-section radio-section-top'>
            <div className='radio-header'>Will you have a co-buyer?</div>
            <Field name='coBuyer' initialValue='no' type='radio'>
              {({ input }) => <RadioOptionGroup input={input} name='coBuyer' type='radio' />}
            </Field>
          </div>
          <div data-testid='co-buyer-input-group'>
            <Condition when='coBuyer' is='yes'>
              <Row className='one-line'>
                <Field name='coBuyerName' validate={required}>
                  {props => <InputField {...props} labelText='Buyer Name*' />}
                </Field>
              </Row>
              <Row className='one-line'>
                <Field name='coBuyerLicenseNumber' validate={required}>
                  {props => <InputField {...props} labelText="Driver's License Number*" />}
                </Field>
              </Row>
              <Row className='one-line'>
                <Field name='coBuyerLicenseState' validate={required}>
                  {props => <SelectField
                    {...props}
                    className='reservation-form-input-field'
                    name='coBuyerLicenseState'
                    classNamePrefix='co-buyer-state'
                    labelText='State'
                    placeholderText="Co-buyer Driver's License State*"
                  />}
                </Field>
              </Row>
              <Row className='one-line'>
                <Field name='coBuyerDob' validate={required}>
                  {props => <DatePickerField
                    {...props}
                    className='co-buyer-dob reservation-form-input-field'
                    labelText='Date of Birth*'
                    placeholderText='Co-buyer Date of Birth (mm/dd/yyyy)*'
                  />}
                </Field>
              </Row>
            </Condition>
          </div>
          <div className='radio-section'>
            <div className='radio-header'>Are you financing this vehicle?</div>
            <Field name='financing' initialValue='no' type='radio'>
              {({ input }) => <RadioOptionGroup input={input} name='financing' type='radio' />}
            </Field>
          </div>
          <div data-testid='financing-group'>
            <Condition when='financing' is='yes'>
              <Row className='two-line'>
                <Field name='bankName' validate={required}>
                  {props => <InputField {...props} labelText='Bank Name*' />}
                </Field>
                <Field name='loanOfficerName' validate={required}>
                  {props => <InputField {...props} labelText='Loan Officer Name*' />}
                </Field>
              </Row>
              <Row className='two-line'>
                <Field name='bankPhone' validate={required} type='number'>
                  {props => <InputFieldMask {...props} maskPlaceholder='Bank Phone' mask='999-999-9999' />}
                </Field>
                <Field name='salesTax' validate={required}>
                  {props => <SalesTaxField
                    {...props}
                    className='reservation-form-input-field'
                    name='salesTax'
                    classNamePrefix='sales-tax-handling'
                    labelText='Sales Tax Handling*'
                    placeholderText="How are you handling the tax?"
                  />}
                </Field>
              </Row>
            </Condition>
          </div>
          <div className='radio-section'>
            <div className='radio-header'>Would you like to order a cosmetic inspection?</div>
            <Field name='inspection' initialValue='no' type='radio'>
              {({ input }) => <RadioOptionGroup input={input} name='inspection' type='radio' />}
            </Field>
          </div>
          <div className='radio-section'>
            <div className='radio-header'>Would you like a quote on a vehicle protection plan?</div>
            <Field name='protection' initialValue='no' type='radio'>
              {({ input }) => <RadioOptionGroup input={input} name='protection' type='radio' />}
            </Field>
          </div>
          <div className='reservation-form-terms'>
            <Terms />
          </div>
          <Row>
            <Field name='terms' initialValue={false} type='checkbox' validate={required}>
              {props => <CheckboxOption {...props} />}
            </Field>
          </Row>
          <ReCAPTCHAContainer error={reCaptchaError} onError={setReCaptchaError} onVerify={setReCaptchaVerified} />
          <div className='reservation-button-section'>
            <button id='reserve-btn' className='reservation-button'>RESERVE VEHICLE</button>
          </div>
        </form>
      )}
    />
  </Col>
}

export default ReservationForm
