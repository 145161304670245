import './styles.scss'

import React, { Fragment, useState } from 'react'

export const RadioOptionGroup = ({ name, disabled, input, type }) => {
  const [checkedOne, setCheckedOne] = useState(true)
  const [checkedTwo, setCheckedTwo] = useState(false)

  let setRadios = (number) => {
    if ((number === 1 && checkedOne) ||
      (number === 2 && checkedTwo)) return

    setCheckedOne(!checkedOne)
    setCheckedTwo(!checkedTwo)
  }

  const generateOption = ({ count, value, checked, text }) =>
    <div key={`${name}-${count}`} className='radio-option'>
      <input
        {...input}
        data-testid={name}
        type={type}
        value={value}
        name={name}
        onClick={() => setRadios(count)}
        checked={checked}
        disabled={disabled}
      />
      <span className='checkmark' />
      <div className='radio-option-label'>{text}</div>
    </div>

  return <Fragment>
    {[
      { count: 1, value: 'no', checked: checkedOne, text: 'No' },
      { count: 2, value: 'yes', checked: checkedTwo, text: 'Yes' }
    ].map(generateOption)}
  </Fragment>
}
