import './styles.scss'

import React, { Fragment } from 'react'

import ChevronUp from '@Assets/icon-chevron-up.png'
import { Link } from 'gatsby'

const BarHeader = ({ year, make, model, text }) =>
  <Fragment>
    <div className='back'>
      <Link to='/Search/'>
        <img src={ChevronUp} alt='chevron-up' className='chevron-left' />
        <span className='back-text'>Back</span>
      </Link>
    </div>

    <div className='bar-header'>
      {text ? text : `${year} ${make} ${model}`}
    </div>
  </Fragment>

export default BarHeader
