import { Error } from './Error'
import InputMask from 'react-input-mask'
import React from 'react'

export const InputFieldMask = ({ testId, input, meta, labelText, mask, maskPlaceholder }) =>
    <div data-testid={testId} className="reservation-form-input-field">
        {input.value && <div className="label fade-in"><label>{labelText}</label></div>}
        <InputMask onChange={input.onChange} onBlur={input.onBlur} value={input.value} placeholder={maskPlaceholder} mask={mask} />
        <Error {...meta} />
    </div>
