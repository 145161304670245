import DatePicker from 'react-datepicker'
import { Error } from './Error'
import React from 'react'

export const DatePickerField = ({ testId, className, input, meta, labelText, placeholderText }) =>
    <div data-testid={testId} className={className}>
        {!meta.pristine && <div className="label fade-in"><label>{labelText}</label></div>}
        <DatePicker
            selected={input.value}
            onChange={input.onChange}
            placeholderText={placeholderText} />
        <Error {...meta} />
    </div>
