import './styles.scss'

import React, { useState } from 'react'

export const CheckboxOption = ({ name, disabled, input, meta }) => {
  const [checkedState, setCheckedState] = useState(false)

  return <div className='checkbox-option'>
    <input {...input} type='checkbox' onClick={() => setCheckedState(!checkedState)} value={checkedState} name={name} checked={checkedState} disabled={disabled} />
    <span className='checkmark-two'></span>
    <div className='checkbox-option-label'>I AGREE TO THE TERMS & CONDITIONS</div>
    {meta.error && meta.touched && <div className='checkbox-error'>{meta.error}</div>}
  </div>
}
