import '@Components/Pages/Reservation/styles.scss'

import React, { useState } from 'react'

import BarHeader from '@Components/shared/Headers/BarHeader'
import ReservationForm from '@Components/Pages/Reservation/ReservationForm'
import { Row } from 'react-bootstrap'
import { navigate } from 'gatsby'
import noPhoto from '@Assets/noPhoto.png'
import { numberWithCommas } from '@Helpers'

const Reservation = props => {
  const [apiError, setApiError] = useState('')

  const { year, make, model, price, mileage, vin, carPhoto } = typeof window === 'undefined' ? {} : props.location.state

  const backToShop = () => navigate('/Search/')

  return <div className='reservation'>
    {apiError === 409 && <div className='reserved-error-overlay'>
      <div className='reserved-error-overlay-box'>
        <div className='reserved-error-overlay-box-text'>
          This vehicle has already been reserved. We apologize for any inconvenience.
            </div>
        <button className='reserved-error-overlay-box-button' onClick={backToShop}>
          Back to shop
            </button>
      </div>
    </div>}
    <BarHeader text='Reserve Vehicle' />
    <div className='reservation-info'>
      <div className='reservation-info-pic'>
        <img src={carPhoto || noPhoto} alt='car-photos' />
      </div>
      <div className='reservation-info-text'>
        <div className='reservation-info-text-car'>
          <div className='reservation-info-text-car-title'>{year} {make} {model}</div>
          <div className='reservation-info-text-car-price'>${price && numberWithCommas(price)}</div>
          <Row>
            <div className='reservation-info-text-car-sub-title-1'>
              Mileage
                  <div className='reservation-info-text-car-sub-text'>{mileage && numberWithCommas(mileage)}</div>
            </div>
            <div className='reservation-info-text-car-sub-title-2'>
              VIN
                  <div className='reservation-info-text-car-sub-text'>{vin}</div>
            </div>
          </Row>
        </div>
        <Row>
        </Row>
        <p>
          Please fill in the name(s) and the physical address exactly as you want it
          to appear on your Title and Registration. Please provide a valid
          email address, as this will be the preferred method for communicating
          information pertaining to your vehicle purchase and this program.
        </p>
      </div>
    </div>
    <ReservationForm history={props.history} location={props.location} setApiError={setApiError} />
  </div>
}

export default Reservation
